import {
  masterschool_logo,
  mindguard,
  noLedgeloss,
  srisa,
} from "../assets/companies";

// TODO: add a unique id to each application

export const applications = [
  {
    id: "diosa7958a7isdjkhaskjfhgkajshdkasjh",
    company: "Mind Guard",
    image: mindguard,
    domain: "data",
    job: "Data Analyst",
    location: "Remote",
    link: "https://www.mindguard.com/",
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque animi magni quo dicta eaque alias distinctio labore veniam adipisci. Quas excepturi dolor culpa deserunt, quasi dolorum rem mollitia? Minima laboriosam esse vero perferendis officia! Consequuntur tenetur nulla veniam perspiciatis assumenda enim, praesentium eaque quod quia officiis culpa quam explicabo odio facere fugiat beatae, tempore aspernatur itaque laborum corrupti iure et.",
    applicationDate: {
      month: "November",
      year: 2024,
    },
    tags: [
      {
        type: "salary",
        content: "15€/hour",
      },
      {
        type: "location",
        content: "Remote",
      },
      {
        type: "time",
        content: "Part-time",
      },
      {
        type: "experience",
        content: "Entry-level",
      },
      {
        type: "duration",
        content: "6 Months",
      },
    ],
    open: false,
  },
  {
    id: "foaug7a897adhsahuidashjkdha",
    company: "Mind Guard",
    image: mindguard,
    domain: "data",
    job: "BI Analyst",
    location: "Remote",
    link: "https://www.mindguard.com/",
    description:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non quo ipsa, voluptates deserunt commodi possimus. Obcaecati, necessitatibus laboriosam dolorum, non officia repellendus distinctio natus animi similique delectus excepturi alias.",
    applicationDate: {
      month: "November",
      year: 2024,
    },
    tags: [
      {
        type: "salary",
        content: "15€/hour",
      },
      {
        type: "location",
        content: "On-Site",
      },
      {
        type: "time",
        content: "full-time",
      },
      {
        type: "experience",
        content: ">1 Year",
      },
      {
        type: "duration",
        content: "6 Months",
      },
    ],
    open: true,
  },
  {
    id: "djsha98r7as9d8uihaskjdhs",
    company: "Mind Guard",
    image: mindguard,
    domain: "software",
    job: "Software Engineer",
    location: "Remote",
    link: "https://www.mindguard.com/",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam quo dolorum tempora suscipit quae accusantium natus pariatur in placeat voluptatum. Explicabo, doloribus? Doloribus molestias autem deserunt dolorem vitae, deleniti eligendi dignissimos harum maiores, sed porro nostrum culpa facere quasi, quisquam velit magnam?",
    applicationDate: {
      month: "November",
      year: 2024,
    },
    tags: [
      {
        type: "salary",
        content: "15€/hour",
      },
      {
        type: "location",
        content: "Remote",
      },
      {
        type: "time",
        content: "Part-time",
      },
      {
        type: "experience",
        content: "Entry-level",
      },
      {
        type: "duration",
        content: "3 Months",
      },
    ],
    open: false,
  },
  {
    id: "d90as79t8yakdjhsajkhdasj",
    company: "NoLedgeLoss",
    image: noLedgeloss,
    domain: "software",
    job: "Software Engineer",
    location: "Remote",
    link: "https://www.noledgeloss.com/",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum numquam deserunt mollitia, quidem debitis delectus omnis ad tempore fugiat illum excepturi tempora sequi nam quod rerum ab harum corrupti doloribus sapiente? Nesciunt explicabo magni obcaecati. Cumque distinctio dignissimos reprehenderit eum laboriosam sequi exercitationem sint ipsam corporis officia impedit dolore dolores porro incidunt, fugiat earum nobis delectus assumenda inventore in voluptatum.",
    applicationDate: {
      month: "October",
      year: 2024,
    },
    tags: [
      {
        type: "salary",
        content: "18€/hour",
      },
      {
        type: "location",
        content: "Remote",
      },
      {
        type: "time",
        content: "Part-time",
      },
      {
        type: "experience",
        content: ">1 Year",
      },
      {
        type: "duration",
        content: "6 Months",
      },
    ],
    open: true,
  },
  {
    id: "idusat897asoidhakjsdhaks",
    company: "Masterschool",
    image: masterschool_logo,
    domain: "software",
    job: "Code Reviewer (Python)",
    location: "Remote",
    link: "https://www.masterschool.com/",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum numquam deserunt mollitia, quidem debitis delectus omnis ad tempore fugiat illum excepturi tempora sequi nam quod rerum ab harum corrupti doloribus sapiente? Nesciunt explicabo magni obcaecati. Cumque distinctio dignissimos reprehenderit eum laboriosam sequi exercitationem sint ipsam corporis officia impedit dolore dolores porro incidunt, fugiat earum nobis delectus assumenda inventore in voluptatum.",
    applicationDate: {
      month: "October",
      year: 2024,
    },
    tags: [
      {
        type: "salary",
        content: "20€/hour",
      },
      {
        type: "location",
        content: "Remote",
      },
      {
        type: "time",
        content: "Part-time",
      },
      {
        type: "experience",
        content: ">1 Year",
      },
      {
        type: "duration",
        content: "6 Months",
      },
    ],
    open: true,
  },
  {
    id: "d90as8t98yahskjdhsakdjhs",
    company: "Masterschool",
    image: masterschool_logo,
    domain: "software",
    job: "Software Engineering Mentor",
    location: "Remote",
    link: "https://www.masterschool.com/",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum numquam deserunt mollitia, quidem debitis delectus omnis ad tempore fugiat illum excepturi tempora sequi nam quod rerum ab harum corrupti doloribus sapiente? Nesciunt explicabo magni obcaecati. Cumque distinctio dignissimos reprehenderit eum laboriosam sequi exercitationem sint ipsam corporis officia impedit dolore dolores porro incidunt, fugiat earum nobis delectus assumenda inventore in voluptatum.",
    applicationDate: {
      month: "October",
      year: 2024,
    },
    tags: [
      {
        type: "salary",
        content: "30€/hour",
      },
      {
        type: "location",
        content: "Remote",
      },
      {
        type: "time",
        content: "Part-time",
      },
      {
        type: "experience",
        content: ">2 Years",
      },
      {
        type: "duration",
        content: "3 Months",
      },
    ],
    open: true,
  },
  {
    id: "d9as7t98aouhdkjsahdkjhsahdj",
    company: "International School of Art",
    image: srisa,
    isImageWhite: true,
    domain: "software",
    job: "Software Engineer",
    location: "Remote",
    link: "https://www.srisa.org/",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum numquam deserunt mollitia, quidem debitis delectus omnis ad tempore fugiat illum excepturi tempora sequi nam quod rerum ab harum corrupti doloribus sapiente? Nesciunt explicabo magni obcaecati. Cumque distinctio dignissimos reprehenderit eum laboriosam sequi exercitationem sint ipsam corporis officia impedit dolore dolores porro incidunt, fugiat earum nobis delectus assumenda inventore in voluptatum.",
    applicationDate: {
      month: "October",
      year: 2024,
    },
    tags: [
      {
        type: "salary",
        content: "12.5€/hour",
      },
      {
        type: "location",
        content: "Remote | On-Site",
      },
      {
        type: "time",
        content: "Part-time",
      },
      {
        type: "experience",
        content: ">2 Years",
      },
      {
        type: "duration",
        content: "3 Months",
      },
    ],
    open: true,
  },
];
