import {
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const JobCard = ({ app }) => {
  return (
    <Card p={5} width='800px' maxWidth='100%' mb={5}>
      <Flex>
        <CardBody>
          <Flex
            w='100%'
            justifyContent='space-between'
            alignItems='center'
            mb={3}
          >
            <Heading fontWeight='thin' size='md'>
              <Text as='b'>{app.job} </Text>| {app.company}
            </Heading>
            <Image
              ml={3}
              height={app.isImageWhite ? "40px" : "40px"}
              width={app.isImageWhite ? "60px" : "40px"}
              objectFit='contain'
              src={app.image}
              alt={app.company}
              p={app.isImageWhite ? [2, 2, 2, 2, 3] : ""}
              bg={app.isImageWhite ? "#333" : ""}
              borderRadius={app.isImageWhite ? "xl" : ""}
            />
          </Flex>
          <Show above='md'>
            <Text mb={5}>{app.description}</Text>
          </Show>
          <Hide above='md'>
            <Text mb={5} fontSize='sm'>
              {app.description.slice(0, 100) + "..."}
            </Text>
          </Hide>
          <Flex mb={3} gap={3} wrap={"wrap"}>
            {app.tags.map((tag) => (
              <Tag fontSize='xs'>{tag.content.toUpperCase()}</Tag>
            ))}
          </Flex>
          {app.open ? (
            <Flex flexDirection='row-reverse' mt={[10, 10, 10, 3, 3]}>
              <Link to={`/apply/${app.id}`}>
                <Button bg='#B9B0E8' color='white'>
                  Apply Now
                </Button>
              </Link>
            </Flex>
          ) : (
            <>
              <Text
                color='#d81f1f'
                mt={[10, 10, 10, 3, 3]}
                fontSize={["sm", "sm", "sm", "md", "md"]}
              >
                Application Starts On: {app.applicationDate.month}{" "}
                {app.applicationDate.year}
              </Text>
            </>
          )}
        </CardBody>
      </Flex>
    </Card>
  );
};

export default JobCard;
