import {
  aiInegration,
  insightGeneration,
  aiPoweredDevelopment,
  modelMonitoring,
} from "../assets/illustrations";

import { BsCodeSlash } from "react-icons/bs";

export const services = [
  {
    title: "Software Development",
    description: [
      "Whether you're building a sophisticated web application or scaling your cloud services, we’ve got you covered. Our software development team is all about creating seamless, functional solutions that fit your needs. From mobile apps to web platforms, we focus on user experience and top-notch functionality to bring your ideas to life. Think of us as your tech partner—ready to transform your vision into reality with development that keeps things smooth, smart, and scalable.",
    ],
    image: aiPoweredDevelopment,
    color: "##B2D7CE",
    background:
      "linear-gradient(180deg, #D9EDF8 0%, rgba(217, 237, 248, 0.3) 100%);",
  },
  {
    title: "Data Analytics",
    description: [
      "We’re not just about crunching numbers; we’re about giving you a clearer vision of where your business is headed. Our goal is to convert your data into a strategic advantage, one that can shape your future, guide your decisions, and give you the insight you need to take the next step in your journey. With us, data isn't just a resource—it’s a roadmap to success.",
    ],
    image: insightGeneration,
    color: "##B2D7CE",
    background:
      "linear-gradient(180deg, #D9EDF8 0%, rgba(217, 237, 248, 0.3) 100%);",
  },

  {
    title: "Cyber Secuirty",
    description: [
      "In today’s digital landscape, security is everything. Our Cyber Security services are designed to protect your business from threats before they even become a problem. We handle everything from vulnerability assessments to penetration testing and security audits, ensuring your data and systems are safe, secure, and compliant. Our proactive approach gives you peace of mind, knowing that your business is always a step ahead of potential cyber risks.",
    ],
    image: modelMonitoring,
    color: "##B2D7CE",
    background:
      "linear-gradient(180deg, #D9EDF8 0%, rgba(217, 237, 248, 0.3) 100%);",
  },
  {
    title: "AI & Machine Learning",
    description: [
      "Ready to take your business to the next level with AI? We’re here to help you integrate cutting-edge AI technologies into your product, making it smarter, faster, and more intuitive. From AI-powered chatbots to advanced machine learning models, we tailor our solutions to fit your unique needs. The goal is simple: to enhance your product with intelligent systems that boost efficiency, user experience, and innovation.",
    ],
    image: aiInegration,
    color: "##B2D7CE",
    background:
      "linear-gradient(180deg, #D9EDF8 0%, rgba(217, 237, 248, 0.3) 100%);",
  },
];
