import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import JobCard from "./Card";
import InputSection from "./InputSection";
import { applications } from "../../data/applications";
import { masterschool_logo } from "../../assets/companies";

const Masterschool = () => {
  const [domain, setDomain] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [filteredApplications, setFilteredApplications] =
    React.useState(applications);

  React.useEffect(() => {
    setFilteredApplications(
      applications
        .filter(
          (app) =>
            domain === "" || app.domain.toLowerCase() === domain.toLowerCase()
        )
        .filter(
          (app) =>
            app.company.toLowerCase().includes(search.toLowerCase()) ||
            app.job.toLowerCase().includes(search.toLowerCase()) ||
            app.description.toLowerCase().includes(search.toLowerCase()) ||
            app.tags.some((tag) =>
              tag.content.toLowerCase().includes(search.toLowerCase())
            )
        )
    );
  }, [domain, search]);

  return (
    <Flex minHeight={"100vh"} flexDirection={"column"} minWidth={"100%"}>
      <Navbar noContact />
      <Flex flexDir='column' mt={20}>
        <Flex flexDir='column' alignItems={"center"}>
          <Image
            mb={5}
            boxSize={"60px"}
            objectFit='contain'
            src={masterschool_logo}
            alt={"masterschool"}
          />
          <Heading size='xl' align='center'>
            Masterschool Partnership
          </Heading>
          <Heading size='lg' align='center'>
            Join Our Network
          </Heading>
          <InputSection
            setDomain={setDomain}
            setSearch={setSearch}
            search={search}
          />
          <Flex
            width='100%'
            maxWidth='1200px'
            alignItems='center'
            flexDir='column'
            mb={100}
          >
            {filteredApplications.length > 0 ? (
              filteredApplications.map((app) => <JobCard app={app} />)
            ) : (
              <Text mt={10}>No application was found.</Text>
            )}
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
};

export default Masterschool;
