import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { applications } from "../../data/applications";
import { Link, useParams } from "react-router-dom";

const Apply = () => {
  const { id } = useParams();

  const app = applications.filter((app) => app.id === id)[0];

  console.log(app);

  return (
    <Flex minHeight='100vh' flexDirection={"column"} minWidth={"100%"}>
      <Navbar noContact />
      <Flex flexDir='column' mt={10} mb='100px' px={5} alignItems='center'>
        <Box width='100%' alignText='left' maxWidth='1000px' mb='20px'>
          <Link to='/masterschool'>{"< Back "}</Link>
        </Box>
        <Flex w='100%' alignItems='center' mb={3} maxWidth='1000px'>
          <Image
            mr={3}
            height={app.isImageWhite ? "40px" : "40px"}
            width={app.isImageWhite ? "60px" : "40px"}
            objectFit='contain'
            src={app.image}
            alt={app.company}
            p={app.isImageWhite ? [2, 2, 2, 2, 3] : ""}
            bg={app.isImageWhite ? "#333" : ""}
            borderRadius={app.isImageWhite ? "xl" : ""}
          />
          <Heading fontWeight='thin' size='lg'>
            <Text as='b'>{app.job} </Text>| {app.company}
          </Heading>
        </Flex>
        <Flex maxWidth='1000px' flexDir='column'>
          <Heading size='md' textAlign='left' w='100%' mb={2} mt={2}>
            Description
          </Heading>
          <Text>{app.description}</Text>
          <Heading size='md' textAlign='left' w='100%' mb={2} mt={2}>
            What we are looking for
          </Heading>
          <Text>{app.description}</Text>
          <Heading size='md' textAlign='left' w='100%' mb={2} mt={2}>
            How to apply
          </Heading>
          <Text>{app.description}</Text>
          <Heading size='md' textAlign='left' w='100%' mb={2} mt={2}>
            Requirements
          </Heading>
          <Text>{app.description}</Text>
          <Heading size='md' textAlign='left' w='100%' mb={2} mt={2}>
            What you will get
          </Heading>
          <Text>{app.description}</Text>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Apply;
