import { Box, Flex, Link, Text } from "@chakra-ui/react";

const Footer = ({ color = "#B9B0E8" }) => {
  const year = new Date().getFullYear();
  return (
    <Box
      as='footer'
      py={8}
      bgColor={color}
      pos='absolute'
      bottom='0'
      left='0'
      width='100%'
    >
      <Flex justifyContent='center' alignItems='center' maxW='1200px' mx='auto'>
        <Text fontSize='sm'>© {year} webeet. All rights reserved.</Text>
      </Flex>
    </Box>
  );
};

export default Footer;
